import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
  listIcon: {
    marginTop: ({ size }) => {
      if (size === 'small') {
        return 2;
      }
      return size === 'medium' ? 3 : 6;
    },
    minWidth: 38,
    opacity: 0.8,
  },
  listItem: {
    alignItems: 'start',
  },
}));
