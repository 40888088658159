import { Fragment } from 'react';
import PropTypes from 'prop-types';
import Fade from 'react-reveal/Fade';
import Box from '@material-ui/core/Box';
import Icon from '@material-ui/core/Icon';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

import useStyles from './ListSimple.styles';

const ListSimple = ({ fade, items, size }) => {
  const classes = useStyles({ size });
  let variant = 'body1';
  if (size === 'medium') {
    variant = 'h6';
  }
  if (size === 'large') {
    variant = 'h5';
  }
  return (
    <Box>
      <List dense>
        {items.map(({ icon, text }, i) => {
          const itemContent = (
            <ListItem className={classes.listItem}>
              {icon && (
                <ListItemIcon className={classes.listIcon}>
                  <Icon color="primary">{icon}</Icon>
                </ListItemIcon>
              )}
              <ListItemText
                primary={text}
                primaryTypographyProps={{ variant }}
              />
            </ListItem>
          );
          return (
            <Fragment key={i}>
              {fade
                ? (
                  <Fade bottom delay={100 * i} ssrReveal>
                    {itemContent}
                  </Fade>
                ) : itemContent}
            </Fragment>
          );
        })}
      </List>
    </Box>
  );
};

ListSimple.defaultProps = {
  fade: false,
  items: [],
  size: 'small',
};

ListSimple.propTypes = {
  fade: PropTypes.bool,
  items: PropTypes.arrayOf(PropTypes.shape({
    icon: PropTypes.node,
    text: PropTypes.string,
  })),
  size: PropTypes.oneOf(['large', 'medium', 'small']),
};

export default ListSimple;
