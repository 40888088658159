import { useTranslation } from 'react-i18next';
import { StaticImage } from 'gatsby-plugin-image';
import clsx from 'clsx';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import { AiOutlineCheck } from 'react-icons/ai';

import SEO from 'components/seo';
import { useMediaQueryTheme } from 'hooks';
import ListSimple from 'components/List/ListSimple';
import AppLayout from 'app/AppLayout/AppLayout';
import useStyles from './Therapies.styles';

const Therapies = () => {
  const { t } = useTranslation();
  const isWidthUpMd = useMediaQueryTheme('md');
  const classes = useStyles();

  return (
    <AppLayout
      headerTransparent={false}
      subheader={{
        title: t('routes.therapies.title'),
      }}
    >
      <SEO
        description={t('routes.therapies.meta.description')}
        title={t('routes.therapies.meta.title')}
      />
      <Container className={classes.root} maxWidth="lg">
        <Typography className={classes.description} variant="body1">
          {t('routes.therapies.description')}
        </Typography>
        <Container maxWidth="lg">
          <Paper className={classes.paper} elevation={4}>
            {isWidthUpMd && (
              <StaticImage
                alt={t('routes.therapies.items.1.title')}
                className={classes.paperImg}
                src="../../assets/img/therapies-1.jpg"
              />
            )}
            <Box className={classes.paperContent}>
              <Typography className={classes.paperTitle} variant="h5">
                {t('routes.therapies.items.1.title')}
              </Typography>
              <ListSimple items={Object.keys(t('routes.therapies.items.1.description')).map(dNum => ({
                icon: <AiOutlineCheck />,
                text: t(`routes.therapies.items.1.description.${dNum}`),
              }))}
              />
            </Box>
            {!isWidthUpMd && (
              <StaticImage
                alt={t('routes.therapies.items.1.title')}
                className={classes.paperImg}
                src="../../assets/img/therapies-1.jpg"
              />
            )}
          </Paper>
          <Paper
            className={clsx(classes.paper, classes.paperBackground2Light)}
            elevation={4}
          >
            <Box className={classes.paperContent}>
              <Typography className={classes.paperTitle} variant="h5">
                {t('routes.therapies.items.2.title')}
              </Typography>
              <ListSimple items={Object.keys(t('routes.therapies.items.2.description')).map(dNum => ({
                icon: <AiOutlineCheck />,
                text: t(`routes.therapies.items.2.description.${dNum}`),
              }))}
              />
            </Box>
            <StaticImage
              alt={t('routes.therapies.items.2.title')}
              className={classes.paperImg}
              src="../../assets/img/therapies-2.jpg"
            />
          </Paper>
          <Paper className={classes.paper} elevation={4}>
            {isWidthUpMd && (
              <StaticImage
                alt={t('routes.therapies.items.3.title')}
                className={classes.paperImg}
                src="../../assets/img/therapies-3.jpg"
              />
            )}
            <Box className={classes.paperContent}>
              <Typography className={classes.paperTitle} variant="h5">
                {t('routes.therapies.items.3.title')}
              </Typography>
              <ListSimple items={Object.keys(t('routes.therapies.items.3.description')).map(dNum => ({
                icon: <AiOutlineCheck />,
                text: t(`routes.therapies.items.3.description.${dNum}`),
              }))}
              />
            </Box>
            {!isWidthUpMd && (
              <StaticImage
                alt={t('routes.therapies.items.3.title')}
                className={classes.paperImg}
                src="../../assets/img/therapies-3.jpg"
              />
            )}
          </Paper>
          <Paper
            className={clsx(classes.paper, classes.paperBackground1Light)}
            elevation={4}
          >
            <Box className={classes.paperContent}>
              <Typography className={classes.paperTitle} variant="h5">
                {t('routes.therapies.items.4.title')}
              </Typography>
              <ListSimple items={Object.keys(t('routes.therapies.items.4.description')).map(dNum => ({
                icon: <AiOutlineCheck />,
                text: t(`routes.therapies.items.4.description.${dNum}`),
              }))}
              />
            </Box>
            <StaticImage
              alt={t('routes.therapies.items.4.title')}
              className={classes.paperImg}
              src="../../assets/img/therapies-4.jpg"
            />
          </Paper>
        </Container>
      </Container>
    </AppLayout>
  );
};

export default Therapies;
