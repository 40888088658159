/* eslint-disable no-dupe-keys */
import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
  description: {
    padding: theme.spacing(0, 2),
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(0, 3),
    },
  },
  paper: {
    display: 'flex',
    flexDirection: 'column',
    margin: theme.spacing(5, 0),
    [theme.breakpoints.up('md')]: {
      flexDirection: 'row',
    },
  },
  paperBackground1Light: {
    background: theme.palette.primary.light,
  },
  paperBackground2: {
    background: theme.palette.secondary.main,
  },
  paperBackground2Light: {
    background: theme.palette.secondary.light,
  },
  paperContent: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    padding: theme.spacing(2),
  },
  paperImg: {
    maxHeight: 320,
    objectFit: 'cover',
    width: '100%',
    [theme.breakpoints.up('md')]: {
      maxHeight: '100%',
      width: 320,
    },
  },
  paperTitle: {
    marginBottom: theme.spacing(1),
    marginLeft: theme.spacing(2),
  },
  root: {
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(2, 2),
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(3, 3, 16),
    },
  },
}));
